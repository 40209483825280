<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/master">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <div
        v-if="
          permissionPageAccessArry.includes(PermissionVariables.SettingsAdd) ||
            loginUserRole == 'Admin'
        "
        class="vx-col w-full mb-base"
      >
        <GSTForm />
      </div>

      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab
            v-if="
              permissionPageAccessArry.includes(
                PermissionVariables.SettingsAdd
              ) ||
                permissionPageAccessArry.includes(
                  PermissionVariables.SettingsView
                ) ||
                loginUserRole == 'Admin'
            "
            label="GST List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div class="tab-text">
              <div>
                <GSTList />
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import GSTForm from "@/views/apps/masters/gst/GSTForm.vue";
import GSTList from "@/views/apps/masters/gst/GSTList.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    GSTForm,
    GSTList,
  },

  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
    };
  },
};
</script>
