<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>GST List</h4>
          <div class="flex flex-wrap justify-end items-center">
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="designations"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        />
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import MasterService from "@/services/MasterService.js";
import { BASEURL } from "@/config/index.js";
import SearchService from "@/services/searchService.js";
import GSTStatus from "@/views/apps/masters/gst/GSTStatus.vue";
import EditGST from "@/views/apps/masters/gst/EditGST.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
  },
  props: ["listType"],
  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      PermissionVariables: PermissionVariables,
      excelurl: BASEURL,
      archive: this.listType,
      designations: [],
      totalPages: "",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
    };
  },
  beforeMount() {
    eventBus.$on("refreshTablegst", (e) => {
      this.getWorkTypeList(this.currentPage);
    });
    this.columnDefs = [
      {
        headerName: "Name",
        field: "gst_name",
        filter: false,
        width: 400,
        suppressSizeToFit: true,
      },
      {
        headerName: "GST Percentage",
        field: "gst_percentage",
        filter: true,
        width: 300,
        suppressSizeToFit: true,
      },

      {
        headerName: "Status",
        field: "",
        filter: false,
        width: 200,
        sortable: false,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.SettingsAdd
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: GSTStatus,
      },
      {
        headerName: "Edit",
        field: "",
        filter: false,
        width: 200,
        sortable: false,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.SettingsAdd
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EditGST,
      },
    ];
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getWorkTypeList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    updateSearchQuery(val) {
      SearchService.getAllSerchResultV2(val, "master/getGSTData", null)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.designations = data.data;
          } else {
            this.designations = [];
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    getWorkTypeList: function(currentPage) {
      let filter = {
        page: currentPage,
      };
      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      MasterService.getGST(filter)
        .then((response) => {
          const { data } = response;
          if (!data.err) {
            console.log(data);
            this.designations = data.data;
            this.totalPages = data.pagination.last_page;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.checkPermission(["designation", "GET"])) {
      this.getWorkTypeList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
