var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard-analytics"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('router-link',{attrs:{"to":"/master"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","svgClasses":"h-8 w-8"}})],1)],1),(
        _vm.permissionPageAccessArry.includes(_vm.PermissionVariables.SettingsAdd) ||
          _vm.loginUserRole == 'Admin'
      )?_c('div',{staticClass:"vx-col w-full mb-base"},[_c('GSTForm')],1):_vm._e(),_c('div',{staticClass:"vx-col w-full"},[_c('vs-tabs',[(
            _vm.permissionPageAccessArry.includes(
              _vm.PermissionVariables.SettingsAdd
            ) ||
              _vm.permissionPageAccessArry.includes(
                _vm.PermissionVariables.SettingsView
              ) ||
              _vm.loginUserRole == 'Admin'
          )?_c('vs-tab',{attrs:{"label":"GST List","icon-pack":"feather","icon":"icon-box"}},[_c('div',{staticClass:"tab-text"},[_c('div',[_c('GSTList')],1)])]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }